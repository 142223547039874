import React, { Suspense } from 'react';
import './App.scss';
import Test from 'screens/test/test.screen';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Main from 'common_components/hoc/main.hoc';
import store from 'store/store';
import { Provider } from 'react-redux';
import Login from 'screens/login/login.screen';
import Home from 'screens/home/home.screen';
import Navbar from 'components/navbar/navbar.component';
import Details from 'screens/userdetails/userdetails.screen';
import Notification from 'screens/notification/notification.screen';
import Campaign from 'screens/campaign/campaign.screen';
import Result from 'screens/results/results.screen';
import SendNotification from 'screens/send_notification/send_notification.screen'
const token = localStorage.getItem("token");

const NavRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Main >
        <div className="main_container">
          <Navbar />
          <Component {...props} />
        </div>
      </Main>
    )}
  />
);

function App() {
  if (token==null && window.location.pathname == "/" ) {
    window.location.href = "/login";
  }
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div />}>
            <Switch>
              <NavRoute exact path="/" component={Home} />
              <NavRoute exact path="/details" component={Details} />
              <NavRoute exact path="/notification" component={Notification} />
              <NavRoute exact path="/campaign" component={Campaign} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/result" component={Result} />
              <Route exact path="/send_notification" component={SendNotification} />
            </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
