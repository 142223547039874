import auth from 'models/auth.model';
import React from 'react';
import { useSetState } from 'utils/functions.utils';
import { Assets } from 'utils/imports.utils';
import "./home.screen.scss"
import { useSelector, useDispatch } from 'react-redux';
import Userlist from 'components/userlist/userlist.component';
import Navbar from 'components/navbar/navbar.component';
function Home() {
  const [state, setState] = useSetState({});
  const user = useSelector((state: any) => state.user);

  const Home = async () => {

  }

  return (
    <div className="home_screen">
      <div className="content_container">
          <Userlist/>
      </div>
    </div>
  )
}

export default Home;