import auth from 'models/auth.model';
import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import { Assets } from 'utils/imports.utils';
import "./login.screen.scss"  

function Login(props) {

  const [state, setState] = useSetState({ email: "", password: "" });

  useEffect(() => {
    let token = localStorage.getItem("token")
    if (token) {
      window.location.href = "/";
    }

    return () => {

    }
  }, [])


  const Login = async () => {
    try{
      const user: any = await auth.login(state);
      localStorage.setItem("user", user.data._id);
      localStorage.setItem("token", user.token);
      window.location.href = "/";
    }catch(error){
      console.log(error)
    }
  }

  return (
    <div className="login_screen">
      <div className="login_container">
        <div className='img_container'>
          <img className='logo' src={Assets.logo} />
        </div>
        <div className="form">
          <input value={state.email} type="text" placeholder="Email address" onChange={(e) => setState({ email: e.target.value })} />
          <input value={state.password} type="password" placeholder="Password" onChange={(e) => setState({ password: e.target.value })} />
          <div className="login_btn" onClick={() => Login()}>Sign in</div>
        </div>
      </div>
    </div>
  )
}

export default Login;