import auth from 'models/auth.model';
import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import { Assets } from 'utils/imports.utils';
import './notification.screen.scss';
import { useSelector, useDispatch } from 'react-redux';
import Models from 'imports/models.import';
import user from 'models/user.model';
import moment from 'moment';

function Notification(props) {
  const [state, setState] = useSetState({
    image: 'https://icm-invicta.s3.amazonaws.com/s3/1641203411334vic.png',
    title: '',
    message: '',
    user: [],
    notification_list:[]
  });
  const urlParams = new URLSearchParams(window.location.search);

  const sendNotification = async () => {
    if (state.title.length == 0) {
      props.showError('Message is empty');
    } else if (state.message.length == 0) {
      props.showError('Message is empty');
    } else {
      let user = urlParams.get('email');
      let body: any = {
        title: state.title,
        text: state.message,
        image: state.image,
        type: 'all',
        users: [],
      };
      if (user) {
        body.users = [user];
        body.type = 'individual';
      }
      let notification: any = await Models.user.sendNotification(body);
      if (notification.status == 'success') {
        window.location.href = '/';
      }
    }
  };
  const getNotificationList = async () => {
    try {
      let body: any = {
        type: urlParams.get('type'),
      };
      let user = urlParams.get('email');
      if (user) {
        body.user = user;
      }
      const res: any = await Models.campaign.getNotificationList(body);
      let data = res.data
      setState({ notification_list: res.data.reverse() });
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getNotificationList();
  }, []);

  return (
    <div className="notification_screen">
      <div className="title">Send Notification</div>
      <div className='username'>{urlParams.get('name')}</div>
      <div className='email'>{urlParams.get('email_id')}</div>
      <input
        type="text"
        className="notification_title"
        placeholder="Type your title here..."
        onChange={(e) => setState({ title: e.target.value })}
      />
      <textarea
        className="notification_message"
        placeholder="Type your message here..."
        onChange={(e) => setState({ message: e.target.value })}
      />
      <div className="send_btn" onClick={() => sendNotification()}>
        {window.location.search.replace('?email:', '') == ''
          ? 'Send to all'
          : 'Send to selected user'}
      </div>
      <div className="title">Notification History</div>
      <div className="notification_history">
      {state.notification_list.map((campaign) => {
          return (
            <div className="notification_history_wrapper">
              <div className="notification_history_date">
                {moment(campaign.created_at).format('MM/DD/YYYY hh:mm a')}
              </div>
              <div className="notification_history_message">
                {campaign?.message?.title}
              </div>
              <div className="notification_history_message">
                {campaign?.message?.message}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Notification;
