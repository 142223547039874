import React from 'react';
import { Link } from "react-router-dom";
import { Assets } from 'utils/imports.utils';
import "./navbar.component.scss"
const navbar = () => {
  const logout = () =>{
    localStorage.clear()
    console.log("logged out")
    window.location.href = "/login";
  }
  return (
    <div className="navbar_container">
      <div className="logo_container">
        <img className="logo_img" src={Assets.logo} />
      </div>
      <div className="links_container">
        <li>
          <img className="link_img" src={Assets.users} />
          <Link className="links" to="/">All Users</Link>
        </li>
        <li>
          <img className="link_img" src={Assets.notification} />
          <Link className="links" to="/notification?type=all">Notification</Link>
        </li>
        {/* <li>
          <img className="link_img" src={Assets.setting} />
          <Link className="links" to="/">Settings</Link>
        </li> */}
        <li>
          <img className="link_img" src={Assets.logout} />
          <a className="links" onClick={logout}>Logout</a>
        </li>
        <li>
          <img className="link_img" src={Assets.setting} />
          <Link className="links" to="/campaign">Campaign</Link>
        </li>
      </div>
    </div>
  );
}
export default navbar;