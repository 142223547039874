import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import { Assets, Models } from 'utils/imports.utils';
import "./userlist.compoenent.scss"
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from "react-router-dom"
import moment from "moment"
import _ from "lodash"

function Userlist() {
  const [state, setState] = useSetState({
    userlist: [],
    limit: 50,
    skip: 0,
    search:""
  });
  const user = useSelector((state: any) => state.user);
  const history = useHistory();

  useEffect(() => {
    getUserList()
    return () => {
    }
  }, [])


  const getUserList = async () => {
    const user: any = await Models.user.getUser(state.limit, state.skip);
    setState({ userlist: user.data })
  }

  const fetchMoreData = async () => {
    const user: any = await Models.user.getUser(state.limit + 50, state.skip + 50, state.search);
    setState({ userlist: user.data, limit: state.limit + 50, skip: state.skip + 50 })
  }

  const search = async (e) => {
    const user: any = await Models.user.getUser(50, 0, e.target.value);
    setState({ userlist: user.data, search: e.target.value })
  }

  const viewDetails = async (user) => {
    history.push({
      pathname: "/details",
      search: `?email=${user.decrypted_email}`
    })

  }

  const notification = async (user) => {
    history.push({
      pathname: "/notification",
      search: `?email=${user._id}&type=individual&email_id=${user.decrypted_email}&name=${user.name}`
    })

  }

  const getFilteredUserList=()=>{
    return _.filter(state.userlist, function (o) { return o.user_type != "admin"; })
  }


  return (
    <div className="userlist_container">
      <div className="header">
        <div className="title">All Users</div>
        <input
          type="text"
          placeholder="Search with email"
          className="search_input"
          onChange={(e) => search(e)}
        />
      </div>
      <div className="userlist_table_container">
        <div className="table">
          <div className="th serial"></div>
          <div className="th align name">Name</div>
          <div className="th align">Email</div>
          <div className="th align">Created on</div>
          <div className="th action">Send Notification</div>
          <div className="th action">Action</div>

        </div>
        <InfiniteScroll
          dataLength={state.userlist.length}
          next={fetchMoreData}
          style={{ width: "100%", backgroundColor: '#111623' }}
          hasMore={true}
          loader={<div className="loader">Getting users...</div>}
          height={window.innerHeight - (window.innerHeight*0.2)}
          scrollThreshold={0.4}
        >
          {getFilteredUserList().map((user, index) =>
            <div className="table row">
              <div className="th serial">{index + 1}</div>
              <div className="th name">{user.name}</div>
              <div className="th">{user.decrypted_email}</div>
              <div className="th">{moment(user.created_at).format("MM-DD-YYYY hh:mm a")}</div>
              <div className="th action">
                <div className="view" onClick={() => notification(user)}>Send</div>
              </div>
              <div className="th action">
                <div className="view" onClick={() => viewDetails(user)}>View</div>
              </div>
            </div>)}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default Userlist;