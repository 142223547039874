import instance from "utils/axios.utils";

const user = {
  getUser: (limit, skip, search?) => {
    let promise = new Promise((resolve, reject) => {
      let url = "admin/user_list";
      instance().post(url, { limit: limit, skip: skip, search: search }).then(res => {
        resolve(res.data)
      }).catch(error => {
        if (error.response) {
          reject(error.response.data.message)
        } else {
          reject(error)
        }
      })
    })
    return promise
  },

  getUserDetails: (email) => {
    let promise = new Promise((resolve, reject) => {
      let url = "admin/user_details";
      instance().post(url, { email: email }).then(res => {
        resolve(res.data)
      }).catch(error => {
        if (error.response) {
          reject(error.response.data.message)
        } else {
          reject(error)
        }
      })
    })
    return promise
  },
  sendNotification: (body) => {
    // console.log(user, message, title, image)
    let promise = new Promise((resolve, reject) => {
      let url = "/admin/send_notification";
      instance().post(url, body).then(res => {
        resolve(res.data)
      }).catch(error => {
        if (error.response) {
          reject(error.response.data.message)
        } else {
          reject(error)
        }
      })
    })
    return promise
  }

};


export default user;