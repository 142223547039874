import auth from 'models/auth.model';
import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import { Assets } from 'utils/imports.utils';
import './send_notification.screen.scss';
import { useSelector, useDispatch } from 'react-redux';
import Models from 'imports/models.import';
import user from 'models/user.model';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

function SendNotification(props) {
  const [state, setState] = useSetState({
    image: 'https://icm-invicta.s3.amazonaws.com/s3/1641203411334vic.png',
    title: '',
    message: '',
    user: [],
    campaignId: '',
    campaignTitle: '',
    campaignData: [],
  });
  let history = useHistory();
  useEffect(() => {
    getCampaignId();
    getNotificationList();
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const getCampaignId = async () => {
    setState({ loading: true });
    const result: any = await Models.campaign.getCampaignById(
      urlParams.get('campaign_id'),
    );
    setState({
      campaignId: urlParams.get('campaign_id'),
      campaignTitle: urlParams.get('campaign_title'),
      // campaignData: result.data.messages,
    });
  };

  const sendNotification = async () => {
    try {
      if (state.message.length == 0) {
        props.showError('Message is empty');
      } else {
        let req: any = {
          campaign_id: state.campaignId,
          title: state.title,
          text: state.message,
          users: [],
        };
        let type = urlParams.get('type');
        let user = urlParams.get('email');
        if (type) {
          req.type = type;
        }
        if (user) {
          req.users = [user];
        }
        if (state.image !== '') {
          Object.assign(req, { image: state.image });
        }
        const result = await Models.campaign.sendCampaignNotification(req);
        window.location.reload();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getNotificationList = async () => {
    try {
      let body: any = {
        type: urlParams.get('type'),
      };
      let user = urlParams.get('email');
      if (user) {
        body.user = user;
      }
      const res: any = await Models.campaign.getNotificationList(body);
      let data = res.data
      setState({ campaignData: data.reverse() });
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className="notification_screen">
      <div className="title">
        <div className="title_back">
          <div onClick={() => history.goBack()} className="back_link">
            <img
              src={Assets.back_arrow}
              alt="back_arrow"
              height="25px"
              width="25px"
            />
          </div>
        </div>
        <div className="title_text" onClick={() => history.goBack()}>
          <div className="back_link">Send Notification</div>
        </div>
      </div>
      <div className='info_container'>
        <div className='campaign_title'>{state.campaignTitle}</div>
        <div className='username'>{urlParams.get('name')}</div>
        <div className='email'>{urlParams.get('email_id')}</div>
      </div>
      <input
        type="text"
        className="notification_title"
        placeholder="Type your title here..."
        onChange={(e) => setState({ title: e.target.value })}
      />
      <textarea
        className="notification_message"
        placeholder="Type your message here..."
        onChange={(e) => setState({ message: e.target.value })}
      />
      <div className="send_btn" onClick={() => sendNotification()}>
        Send
      </div>
      <div className="title">Notification History</div>
      <div className="notification_history">
        {state.campaignData.map((campaign) => {
          return (
            <div className="notification_history_wrapper">
              <div className="notification_history_date">
                {moment(campaign.created_at).format('MM/DD/YYYY hh:mm a')}
              </div>
              <div className="notification_history_message">
                {campaign.message.title}
              </div>
              <div className="notification_history_message">
                {campaign.message.message}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SendNotification;
