import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import { Assets, Models } from 'utils/imports.utils';
import './campaign_list.component.scss';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import _, { identity } from 'lodash';
import Input from 'components/input_field/input_field.component';
import Modal from 'react-responsive-modal';
import TextEditor from 'components/text_editor/text_editor.component';
import toast, { Toaster } from 'react-hot-toast';

function CampaignList() {
  const [state, setState] = useSetState({
    campaignList: [],
    limit: 50,
    skip: 0,
    search: '',
    open: false,
    title: '',
    description: '',
    startDate: '',
    displayUntil: '',
    endDate: '',
    image: '',
    messages: '',
    edit: false,
    _id: '',
  });
  const user = useSelector((state: any) => state.user);
  const history = useHistory();

  useEffect(() => {
    getCampaignList();
    // getCampaignById();
    if (state.edit) {
      // checkData()
    }
  }, []);

  const getCampaignList = async () => {
    try {
      const res: any = await Models.campaign.getAllCampaign();
      // console.log('res',res)
      return setState({ campaignList: res.data });
    } catch (err) {
      console.log('err', err);
    }
  };
  const update = async (data) => {
    setState({
      open: true,
      edit: true,
      _id: data._id,
      title: data.title,
      description: data.description,
      startDate: moment(data.start_date).startOf('day').format('YYYY-MM-DD'),
      displayUntil: moment(data.display_until).startOf('day').format('YYYY-MM-DD'),
      endDate: moment(data.end_date).format('YYYY-MM-DD'),
    });
  };

  const deleteCampaign = async (campaign) => {
    try {
      await Models.campaign.deleteCampaign(campaign._id);
      window.location.href ="/campaign"
      toast.success('Successfully deleted!');
    } catch (err) {
      console.log(err)
      toast.error('Somthing went wrong');

    }
  }

  const edit = async () => {
    try{
      let body = {
        _id: state._id,
        title: state.title,
        description: state.description,
        start_date: new Date(state.startDate),
        display_until: new Date(state.displayUntil),
        end_date: new Date(state.endDate),
      };
      const res: any = await Models.campaign.editCampaign(body);
      setState({ open: false });
      getCampaignList();
      toast.success('Successfully updated!');
    }catch (err) {
      toast.error('Somthing went wrong');
    }
  };

  const fetchMoreData = async () => {
    const user: any = await Models.user.getUser(
      state.limit + 50,
      state.skip + 50,
      state.search,
    );
    setState({
      campaignlist: user.data,
      limit: state.limit + 50,
      skip: state.skip + 50,
    });
  };

  const addCampaign = async () => {
    try {
      let req = {
        title: state.title,
        description: state.description,
        start_date: new Date(state.startDate),
        display_until: new Date(state.displayUntil),
        end_date: new Date(state.endDate),
      };
      console.log('req', req);
      if (state.image !== '') {
        Object.assign(req, { image: state.image });
      }
      const result = await Models.campaign.addCampaign(req);
      console.log('result', result);
      setState({ open: false });
      getCampaignList();
      toast.success('Successfully updated!');
    } catch (err) {
      console.log('error', err);
      //ts-ignore
      toast.error("Something went wrong");
    }
  };

  const onOpenModal = () =>
    setState({
      open: true,
      edit: false,
      title: '',
      description: '',
      startDate: '',
      displayUntil: '',
      endDate: '',
      image: '',
      messages: '',
    });
  const viewDetails = async (data) => {
    history.push({
      pathname: '/result',
      search: `campaign_id=${data._id}`,
    });
  };
  const onCloseModal = () => setState({ open: false });

  console.log(state.description)

  return (
    <div className="campaignlist_container">
      <Toaster />
      <div className="header">
        <div className="title">Campaign</div>
        <div className="campaign_add_button" onClick={onOpenModal}>
          + Add Campaign
        </div>
      </div>
      <div className="campaignlist_table_container">
        <div className="table">
          <div className="th serial"></div>
          <div className="th align name">Title</div>
          <div className="th align">Start Date</div>
          <div className="th align">End Date</div>
          <div className="th align">Display Until</div>
          <div className="th action"> Delete</div>
          <div className="th action">Edit</div>
          <div className="th action"> Results</div>
        </div>
        <InfiniteScroll
          dataLength={state.campaignList.length}
          next={fetchMoreData}
          style={{ width: '100%', backgroundColor: '#111623' }}
          hasMore={true}
          loader={<div className="loader"></div>}
          height={window.innerHeight - window.innerHeight * 0.2}
          scrollThreshold={0.4}>
          {state.campaignList?.map((campaign, index) => (
            <div className="table row">
              <div className="th serial">{index + 1}</div>
              <div className="th name">{campaign.title}</div>
              <div className="th">
                {moment(campaign.start_date).startOf("day").format('MM/DD/YYYY')}
              </div>
              <div className="th">
                {moment(campaign.end_date).startOf("day").format('MM/DD/YYYY')}
              </div>
              <div className="th response">
                {moment(campaign.display_until).startOf("day").format('MM/DD/YYYY')}
              </div>
              <div className="th action">
                <div className="view" onClick={() => deleteCampaign(campaign)}>
                  Delete
                </div>
              </div>
              <div className="th action">
                <div className="view" onClick={() => update(campaign)}>
                  Edit
                </div>
              </div>
              <div className="th action">
                <div className="view" onClick={() => viewDetails(campaign)}>
                  View
                </div>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
      <Modal
        open={state.open}
        onClose={onCloseModal}
        closeOnOverlayClick
        center
        showCloseIcon={false}
        classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}>
        <div className="campaign_modal_container">
          <div className="modal_header_container">
            <div className="modal_header_text">Add Campaign</div>
            <div
              className="modal_close_button_container"
              onClick={onCloseModal}>
              <img src={Assets.close} alt="close_icon" />
            </div>
          </div>
          <div className="modal_form_container">
            <div className="modal_form_wrapper">
              <div className="modal_input_heading">Campaign Title</div>
              <Input
                onChange={(value) => setState({ title: value })}
                type="text"
                value={state.title}
                name="title"
              />
            </div>
            <div className="modal_form_wrapper">
              <div className="modal_input_heading">Campaign Description</div>
              <TextEditor
                onChange={(value) => {setState({ description: value });}}
                error={state.errorArray}
                name={'description'}
                value={state.description}
              />
            </div>
            <div className="modal_form_wrapper">
              <div className="modal_input_heading">Campaign StartDate</div>
              <input
                type="date"
                data-date-format="MM/DD/YYYY"
                name="start date"
                className="calendar"
                onChange={(value) => {
                  setState({ startDate: value.target.value });
                }}
                value={state.startDate}
              />
            </div>
            <div className="modal_form_wrapper">
              <div className="modal_input_heading">Campaign DisplayUntil</div>
              <input
                type="date"
                data-date-format="DD MM YYYY"
                name="Display Until"
                className="calendar"
                onChange={(value) => {
                  setState({ displayUntil: value.target.value });
                }}
                value={state.displayUntil}
              />
            </div>
            <div className="modal_form_wrapper">
              <div className="modal_input_heading">Campaign EndDate</div>
              <input
                type="date"
                data-date-format="DD MM YYYY"
                name="end date"
                className="calendar"
                onChange={(value) => {
                  setState({ endDate: value.target.value });
                }}
                value={state.endDate}
              />
            </div>
            {/* <div className="modal_form_wrapper">
              <div className="modal_input_heading">Campaign Image</div>
              <input
                type="file"
                name="campaign image"
                width="100"
                height="50"
                onChange={(value) => {
                  setState({ image: value.target.value });
                }}
                value={state.image}
              />
            </div> */}
            <div className="modal_form_submit_container">
              <div
                className="form_submit_button"
                onClick={() => {
                  state.edit ? edit() : addCampaign();
                }}>
                {state.edit ? 'Done' : '+ Add Campaign'}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CampaignList;
