import test from 'models/test.model'
import auth from 'models/auth.model'
import user from 'models/user.model'
import campaign from 'models/campaign.model'


export const Models = {
  test,
  auth,
  user,
  campaign,
}

export default Models