import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './results.screen.scss';
import moment from 'moment';

interface IResult {
  text?: String;
}

const Result = (props: IResult) => {
  // Redux
  const testState = useSelector((state: any) => state.test);
  const history = useHistory();

  // State
  const [state, setState] = useSetState({
    userList: [],
    loading: false,
    campaignId: '',
  });

  //Hooks
  useEffect(() => {
    getUserList();
    // addCampaignData();
  }, []);
  // Network req
  const testReq = async () => {
    try {
      const res: any = await Models.test.testRequest('body');
      console.log(res);
      //dispatch
      testDispatch({});
    } catch (error) {
      console.log(error);
    }
  };

  //Logic
  const testLogic = () => {};

  const getUserList = async () => {
    setState({ loading: true });
    const urlParams = new URLSearchParams(window.location.search);
    const details: any = await Models.campaign.getCampaignUserList({
      campaign_id: urlParams.get('campaign_id'),
    });
    setState({ userList: details.data, loading: false });
  };

  // console.log("user list", state.userList)

  const addCampaignData = async () => {
    try {
      let req = {
        user: '6135ee5f3f32c81dc21f4b2a',
        campaign: '630379577a97905c1c887dab',
        response: 'yes',
        response_date: new Date(),
      };
      console.log('req', req);
      const result = await Models.campaign.addCampaignData(req);
      console.log('result', result);
    } catch (error) {
      console.log('error', error);
    }
  };
  const sendNotification = async (data) => {
    history.push({
      pathname: '/send_notification',
      search: `campaign_id=${data._id}&campaign_title=${data.title}&type=campaign_all`,
      // search:{campaign_id: {data._id}, campaign}
    });
  };
  const sendNotificationToUser = async (data) => {
    history.push({
      pathname: '/send_notification',
      search: `?email=${data._id}&campaign_id=${state?.userList?.campaign._id}&campaign_title=${state?.userList?.campaign.title}&type=individual&name=${data.name}&email_id=${data.email}`,
    });
  };
  const viewDetails = async (data) => {
    history.push({
      pathname: "/details",
      search: `?email=${data.email}`
    })

  }

  return (
    <div className="result_container">
      <div className="result_heading" onClick={() => history.goBack()}>
          <div className="result_back_icon">
            <img
              src={Assets.back_arrow}
              alt="back_arrow"
              height="25px"
              width="25px"
            />
          </div>

        <div className="result_heading_text">

            Results

        </div>
      </div>

      <div className="result_header">
        <div className="result_header_text">
          <div className="result_header_title">
            {state?.userList?.campaign?.title}
          </div>
          {/* <div className="result_header_description">
            {state?.userList?.campaign?.description}
          </div> */}
        </div>
        <div className="result_header_buttons">
          <div
            className="result_send_notification_button"
            onClick={() => sendNotification(state?.userList?.campaign)}>
            Send Notification
          </div>
        </div>
      </div>
      <div className="result_table_container">
        <div className="result_table_header">
          <div className="result_table_serial"></div>
          <div className="result_table_username">User Name</div>
          <div className="result_table_email">Email</div>
          <div className="result_table_response">Response</div>
          <div className="result_table_date">Date</div>
          <div className="th action">Action</div>
          <div className="th action">Send notification</div>
        </div>
        <div className="result_table_body_container">
        {state?.userList?.user?.map((data, index) => (
          <div className="result_table_body">
            <div className="result_table_serial">{index + 1} .</div>
            <div className="result_table_username">{data.name}</div>
            <div className="result_table_email">{data.email}</div>
            <div className="result_table_response">{data.response}</div>
            <div className="result_table_date">
              {moment(data.response_date).format('MM/DD/YYYY')}
            </div>
            <div className="th action">
              <div className="view" onClick={() => viewDetails(data)}>View</div>
            </div>
            <div className="th action">
              <div className="view" onClick={() => sendNotificationToUser(data)}>Send</div>
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default Result;
