import testPic from 'assets/images/dp.jpeg'
import logo from 'assets/images/logo.png'
import back from 'assets/icons/back.png'

import users from 'assets/icons/users.svg'
import notification from 'assets/icons/notification.svg'
import setting from 'assets/icons/setting.svg'
import logout from 'assets/icons/logout.svg'
import close from 'assets/icons/close_black.svg'
import back_arrow from 'assets/icons/back_arrow.svg'

const Assets = {
  testPic,
  logo,
  users,
  logout,
  setting,
  notification,
  back,
  close,
  back_arrow,
}

export default Assets