import instance from '../utils/axios.utils';

const campaign = {
  addCampaign: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'campaign/add_campaign';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log('error', error);
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  getAllCampaign: () => {
    let promise = new Promise((resolve, reject) => {
      let url = 'campaign/all_campaign_list';
      instance()
        .post(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getCampaignById: (id) => {
    let promise = new Promise((resolve, reject) => {
      let url = `campaign/get_campaign/${id}`;
      instance()
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  editCampaign: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'campaign/edit_campaign';
      instance()
        .put(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getCampaignUserList: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'campaign_data/get_user_list';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  addCampaignData: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'campaign_data/add_campaign_data';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  sendCampaignNotification: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'admin/send_campaign_notification';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  deleteCampaign: (id) => {
    let promise = new Promise((resolve, reject) => {
      let url = `campaign/delete_campaign/${id}`;
      instance()
        .delete(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
  getNotificationList: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'notification_data/get';
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },
};

export default campaign;
