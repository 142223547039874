import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import { Assets, Models } from 'utils/imports.utils';
import "./userdetails.compoenent.scss"
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from "react-router-dom"
import { SphereSpinner } from "react-spinners-kit";
import moment from "moment"
import _ from 'lodash';

function Userdetails() {
  const [state, setState] = useSetState({
    userdetails: [],
    loading: false,
  });
  const user = useSelector((state: any) => state.user);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {

    getUserDetails()

    return () => {

    }
  }, [])

  const getUserDetails = async () => {
    setState({ loading: true })
    const details: any = await Models.user.getUserDetails(urlParams.get('email')?.replace(' ', '+'));
    setState({ userdetails: details.data, loading: false })
  }

  const getAnswer = (answers) => {
    if (typeof (answers) === "string") {
      return answers
    } else if (typeof (answers) === "object") {
      for (let key in answers) {
        delete answers[key].id;
        delete answers[key].correct;
      }
      let answer = answers.map(answer => {
        if (typeof (answer) == "string") {
          return answer + "\n\n"
        } else {
          return Object.values(answer).map(answer => {
            return answer + "\n\n"
          })
        }
      })
      return answer
    } else {
      JSON.stringify(answers)
    }
  }

  const sendNotification = async () => {
    history.push({
      pathname: "/notification",
      search: `?email_id=${urlParams.get('email')}&type=individual&name=${state.userdetails.name}&email=${state.userdetails._id}`
    })
  }

  const endDate = (challenges, day) => {
    let filterValue = _.find(challenges, { node: { day } });
    if (!_.isEmpty(filterValue)) {
      return moment(filterValue?.date).format('MM-DD-YYYY hh:mm A')
    } else {
      return null
    }
  }

  return (
    <div className="userdetails_container">
      {state.loading ? (
        <div className="spinner">
          <SphereSpinner size={40} color="#7F65FF" />
        </div>
      ) : (
        <>
          <div className="header">
            <div className="back_container">
              <div className="back_btn" onClick={() => history.goBack()}>
                <img src={Assets.back} />
              </div>
              <div
                className="title"
                  onClick={() => history.goBack()}>
                Back to All users
              </div>
            </div>
            <div className='notification_container'>
              <div
                className="send_notification_button"
                onClick={() => sendNotification()}>
                Send Notification
              </div>
            </div>
          </div>
          <div className="profile_block">
            <div className="name">{state.userdetails.name}</div>
            <div className="email">{urlParams.get('email')}</div>
            <div className="email">
              Engaged Seasons : {state.userdetails?.challenges?.length}
            </div>
            <div className="email">
              Progress :
              {((state.userdetails?.challenges?.length / 6) * 100).toFixed(2)}
            </div>
          </div>
          <div className='table_container'>
            <div className='table_header'>
              <div className='table_season'>Season</div>
              <div className='table_episode'>Current Episode</div>
              <div className='table_status'>Status</div>
              <div className='table_date'>Start Date</div>
              <div className='table_date'>End Date</div>
            </div>
            {state?.userdetails.challenges && state?.userdetails?.challenges?.map(data =>
              <div className="table_body">
                {data?.challenge_dates? data?.challenge_dates.map((info) => (
                  <div className='table_wrapper'>
                    <div className="table_season">
                      {data?.micro_lession.name}
                    </div>
                    <div className="table_episode">{info?.day}</div>
                    <div className='table_status'>{endDate(data.challenges, info.day) ? "Completed" : "In Progress"}</div>
                    <div className="table_date">
                      {moment(info?.start_date).format('MM-DD-YYYY hh:mm A')}
                    </div>
                    <div className='table_date'> {(endDate(data.challenges, info.day)) ? (endDate(data.challenges, info.day)) : " "}</div>
                  </div>
                )) : <div style={{color: '#fff',marginTop:10, fontSize:14}}>No data available</div>}
              </div>
            )}
          </div>
          {state?.userdetails && state?.userdetails?.challenges?.map((details) => (
            <div className="details_container">
              <div className="details_title">{details.micro_lession.name}</div>
              <div className="details_episode">
                Answered Challenges {details.challenges.length}
              </div>

              <div className="details_block">
                {details.challenges.map((challenge) => (
                  <div className="details_answer_block">
                    <div className="answer_card">
                      <div className="question_description">
                        {challenge?.node?.data?.description}
                      </div>
                      <div className="question_title">
                        {challenge?.node?.data?.title}
                      </div>
                      <div className="question_subtitle">
                        {challenge?.node?.data?.subtitle}
                      </div>
                      <div className="question_date">
                        Completed on :
                        {moment(challenge?.date).format('MM-DD-YYYY hh:mm a')}
                      </div>
                      <div className="answer">
                        Answer:
                        <br />
                        <br />
                        {getAnswer(challenge.answer)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {details.feedback && (
                <div className="details_feedback">Feedback Answers</div>
              )}

              {details.feedback && (
                <div className="details_block">
                  {details?.feedback.map((feedback) => (
                    <div className="details_answer_block">
                      <div className="answer_card">
                        <div className="question_title">
                          {feedback?.question}
                        </div>
                        <div className="answer">
                          Answer:
                          <br />
                          <br />
                          {getAnswer(feedback?.answer)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default Userdetails;