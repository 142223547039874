import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useSetState } from 'utils/functions.utils';
import { Functions, Models, Assets } from 'utils/imports.utils';
import { testDispatch } from 'utils/redux.utils';
import './campaign.screen.scss';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Input from '../../components/input_field/input_field.component';
import CampaignList from '../../components/campaignlist/campaign_list.component'

interface ICampaign {
  text?: String;
}

const Campaign = (props: ICampaign) => {
  // Redux
  const testState = useSelector((state: any) => state.test);

  // State
  const [state, setState] = useSetState({ open: false });

  //Hooks
  useEffect(() => {}, []);

  // Network req
  //   const testReq = async () => {
  //     try {
  //       const res: any = await Models.test.testRequest('body');
  //       console.log(res);
  //       //dispatch
  //       testDispatch({});
  //     } catch (error: any) {
  //       console.log(error);
  //     }
  //   };

  //Logic
  const testLogic = () => {};
  const onOpenModal = () => setState({ open: true });
  const onCloseModal = () => setState({ open: false });

  return (
    <div className="campaign_screen">
      <CampaignList />
    </div>
  );
};

export default Campaign;
